<template >
  <div>
    <v-row>
      <v-col v-if="!show">
        <custom-form-action-btn
          :key="`back-btn`"
          :cols="{ cols: 12 }"
          :defaultKey="defaultKey"
          :action="{ key: 'click', handler: goToOrderDetail }"
          color="primary"
          label="返回訂單明細"
        ></custom-form-action-btn>
      </v-col>

      <!-- 步驟一 -->
      <template v-else-if="this.stage == 1">
        <v-col>
          <custom-form-action-btn
            :key="`back-${this.stage}`"
            :cols="{ cols: 6 }"
            :defaultKey="defaultKey"
            :action="{ key: 'click', handler: goToOrderDetail }"
            color="primary"
            outlined
            label="取消"
          ></custom-form-action-btn>
        </v-col>

        <v-col>
          <custom-form-action-btn
            :key="`next-${this.stage}`"
            :cols="{ cols: 6 }"
            :defaultKey="defaultKey"
            :action="{ key: 'click', handler: handleNext }"
            color="primary"
            label="下一步"
          ></custom-form-action-btn>
        </v-col>
      </template>
      <!-- 步驟二 -->
      <template v-else-if="this.stage == 2">
        <v-col>
          <custom-form-action-btn
            :key="`back-${this.stage}`"
            :cols="{ cols: 6 }"
            :defaultKey="defaultKey"
            :action="{ key: 'click', handler: handlePrevious }"
            color="primary"
            outlined
            label="上一步"
          ></custom-form-action-btn>
        </v-col>

        <v-col>
          <custom-form-action-btn
            :key="`next-${this.stage}`"
            :cols="{ cols: 6 }"
            :defaultKey="defaultKey"
            :action="{ key: 'click', handler: validateNext }"
            color="primary"
            label="下一步"
          ></custom-form-action-btn>
        </v-col>
      </template>
      <!-- 步驟三 -->
      <template v-else-if="this.stage == 3">
        <v-col>
          <custom-form-action-btn
            :key="`back-${this.stage}`"
            :cols="{ cols: 6 }"
            :defaultKey="defaultKey"
            :action="{ key: 'click', handler: handlePrevious }"
            color="primary"
            outlined
            label="上一步"
          ></custom-form-action-btn>
        </v-col>

        <v-col>
          <custom-form-action-btn
            :cols="{ cols: 6 }"
            :action="{ key: 'submit' }"
            :key="defaultKey"
            color="primary"
            label="送出"
          ></custom-form-action-btn>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script lang="babel" type="text/babel">

import orderConstants from "@/modules/base/config/orderConstants"
import ItemMixin from '@/components/form/custom/ItemMixin.js'
import customFormActionBtn from "@/components/form/custom/action/customFormActionBtn.vue"

export default {
    components : {
        customFormActionBtn
    },
    mixins: [ItemMixin],
    props : {
        orderStatus : String,
        defaultKey : String,
        stage : Number,
        show: Boolean,
        handleNextStep : Function
    },
    data: () => ({
        data : null,
    }),
    async created() { 

    },
    computed: {
        providerId() {
          return this.$route.params.providerId
        },
        getValid() {
          return this.$store.getters[`customForm/${this.defaultKey}/getValid`]
        },
        getValidateMessage() {
            return this.$store.getters[`customForm/${this.defaultKey}/getValidateMessage`]
        },
    },
    watch : {
        
 
    },
    methods: {
        // 強制還原表單內容
        refreshForm() {
            this.$parent.$emit('forceRefresh') 
        },
        async validateNext() {
            const res = this.$store.getters[`customForm/${this.formKey}/getControl`].validate()
            await this.$nextTick()
            if(this.getValid) {
                this.handleNext()
                return
            }

            this.getValidateMessage && this.$snotify.error(this.getValidateMessage)
        },
        handleNext() {
            this.$emit('handleNextStep')
        },
        handlePrevious () {
      
            this.$emit('handlePreviousStep');
        },
        goToOrderDetail() {
            this.$router.push({
                name: "store-order-detail",
                params: { orderId: this.$route.params.orderId ,providerId: this.providerId },
            });
        }

    },
}
</script>

